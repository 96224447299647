<template>
    <div class="appoints container">
        <div>
            <div v-if="loaded && appoints.length === 0" style="text-align: center; font-size: 16px; color: #666; margin-top: 50px;">
                未查询到预约订单!
            </div>
            <div class="appoint" v-for="(appoint, index) in appoints" :key="index">
                <div v-if="appoint.appointType === 'NORMAL'">
                    <van-cell title="订单编号" label="Order" :value="appoint.appointNo" title-class="mtitle"
                              label-class="mlabel"></van-cell>
                    <van-cell title="石窟选择" label="Grottoe" :value="appoint.scenic.name" title-class="mtitle"
                              label-class="mlabel"></van-cell>
                    <van-cell title="参观日期" label="Date" :value="appoint.date" title-class="mtitle"
                              label-class="mlabel"></van-cell>
                    <van-cell title="进场时间" label="Time" :value="appoint.timeLabel" title-class="mtitle"
                              label-class="mlabel"></van-cell>
                </div>
                <div v-if="appoint.appointType === 'VISIT'" style="position: relative;">
                    <img src="@/assets/image/visit-badge.png" class="m-badge">
                    <van-cell title="订单编号" label="Order" :value="appoint.appointNo" title-class="mtitle"
                              label-class="mlabel"></van-cell>
                    <van-cell title="参观日期" label="Date" :value="appoint.date" title-class="mtitle"
                              label-class="mlabel"></van-cell>
                    <van-cell title="预约石窟" label="Grottoe" :value="appoint.scenics.map(it => it.name).join('、')"
                              title-class="mtitle"
                              label-class="mlabel"></van-cell>
                </div>
                <div class="btn-bar">
                    <van-button type="info" class="btn-primary-plain" plain round @click="view"
                                :data-no="appoint.appointNo">
                        查看二维码<br>
                        <span style="font-size: 10px;">QR Code</span></van-button>
                    <van-button type="info" class="btn-primary-plain" plain round @click="cancel"
                                :data-id="appoint.id" :data-date="appoint.date">
                        取消预约<br>
                        <span style="font-size: 10px;">Cancel</span></van-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Vue from 'vue'
    import { Field, Cell, CellGroup, Toast, Button, Form, Dialog } from 'vant'
    import 'vant/lib/field/style'
    import 'vant/lib/cell/style'
    import 'vant/lib/cell-group/style'
    import 'vant/lib/button/style'
    import 'vant/lib/form/style'
    import 'vant/lib/dialog/style'

    Vue.use(Field)
    Vue.use(Cell)
    Vue.use(CellGroup)
    Vue.use(Button)
    Vue.use(Form)
    Vue.use(Dialog)

    export default {
        data () {
            return {
                user: {
                    cardNo: null,
                    mobile: null
                },
                appoints: [],
                loaded: false
            }
        },
        created () {
            let that = this
            that.user.cardNo = that.$route.query.cardNo
            that.user.mobile = that.$route.query.mobile
            if (!that.user.cardNo || !that.user.mobile) {
                that.$router.push({ name: 'appointsQuery' })
            } else {
                that.$http.get('/v1/appoints', { params: that.user }).then(res => {
                    that.appoints = res.data
                    that.loaded = true
                })
            }
        },
        methods: {
            cancel (event) {
                let that = this
                let id = event.currentTarget.dataset.id
                let date = event.currentTarget.dataset.date
                Dialog.confirm({
                    title: '取消预约',
                    message: `确认取消您 ${date} 的石窟预约吗?`
                }).then(() => {
                    that.$http.delete('/v1/appoints/' + id).then(res => {
                        if (res.code === '0') {
                            Toast('取消预约成功!')
                            that.appoints = that.appoints.filter(appoint => appoint.id !== parseInt(id))
                        }
                    })
                }).catch(() => {
                })
            },
            view (event) {
                let no = event.currentTarget.dataset.no
                this.$router.push({ name: 'appoint', params: { no: no } })
            }
        }
    }

</script>
<style lang="scss">
    .appoints {
        .appoint {
            padding-left: 10px;
            background-color: #FFFFFF;
            margin-top: 20px;

            .m-badge {
                position: absolute;
                right: 0;
                top: 0;
                height: 24px;
                z-index: 10;
            }

            .btn-bar {
                border-top: #F6F6F6 8px solid;
                padding: 15px 15px;
                margin-left: -10px;
                text-align: right;

                .van-button {
                    line-height: 14px !important;
                    padding-top: 4px;
                    margin-left: 10px;
                }
            }
        }

        .van-cell__value {
            text-align: left;
        }
    }
</style>
